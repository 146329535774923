import { useCallback } from "react";

import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openCreateJobModal } from "@js/apps/employer/components/create-job-modal";
import { useOpenJobsModule } from "@js/apps/employer/hooks/open-jobs-module";
import { ModalInstance } from "@js/components/modal";
import { useNavigate } from "@js/hooks";

import styles from "./styles.module.scss";

export const openAirAiPopupModal = ({ onClose }: { onClose: () => void }) => {
  const handleOnClose = () => {
    onClose?.();
    ModalInstance.close();
  };

  ModalInstance.open({
    className: styles.airAiPopup,
    padding: false,
    keepOnBackdropClick: true,
    closeButton: true,
    closeButtonProps: {
      sx: {
        backgroundColor: "var(--grey-5) !important",
        height: "40px",
        width: "40px",
      },
    },
    onClose: handleOnClose,
    children: <AirAiPopupContent onClose={handleOnClose} />,
  });
};

export const AirAiPopupContent = ({ onClose }) => {
  const navigate = useNavigate();
  const { totalOpenJobsCount } = useOpenJobsModule();
  const isMobile = useMediaQuery("sm");

  const handleGetStarted = useCallback(() => {
    onClose();
    if (totalOpenJobsCount > 0) {
      navigate("/employer/dashboard/my_jobs/");
    } else {
      openCreateJobModal();
    }
  }, [navigate, onClose, totalOpenJobsCount]);

  return (
    <Stack direction="column">
      <Stack direction="column" useFlexGap spacing={2} p={3} paddingBottom={0}>
        <Box
          display="flex"
          justifyContent={isMobile ? "start" : "center"}
          alignItems="center"
          gap={1}
        >
          <img
            src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
            alt="air-ai"
            height={32}
          />
          <Typography
            component="h1"
            variant="label"
            size={isMobile ? "small" : "medium"}
          >
            Introducing AI Interviews
          </Typography>
        </Box>
        <img src={`${SETTINGS.STATIC_URL}jobs/air-ai.png`} alt="air-ai" />
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        p={3}
        bgcolor="var(--black)"
        alignItems="center"
      >
        <Stack
          direction="column"
          useFlexGap
          spacing={1}
          bgcolor="var(--black)"
          alignItems="center"
          maxWidth={568}
        >
          <img
            src={`${SETTINGS.STATIC_URL}jobs/air-ai-avatar.png`}
            alt="air-ai"
            height={87}
          />
          <Typography
            component="h2"
            variant="title"
            color="white"
            fontWeight={400}
            size={isMobile ? "small" : "medium"}
          >
            AI interviews &amp; grading
          </Typography>
          <Typography
            component="p"
            variant="paragraph"
            color="white"
            textAlign="center"
            size={isMobile ? "small" : "medium"}
          >
            Our AI will conduct initial video interviews and grade candidates
            based on the criteria you set, providing you with a detailed
            scorecard to make fast, informed hiring decisions and ensuring a
            consistent interview experience for all candidates.
          </Typography>
        </Stack>
        <Button
          shape="squared"
          variant="black-outlined"
          size={isMobile ? "x-small" : "small"}
          state={{ noScroll: true }}
          sx={{
            whiteSpace: "nowrap",
            flexShrink: 0,
            gap: 1,
            alignSelf: "end",
            background:
              "linear-gradient(309.05deg, #D4E4F9 9.33%, #E9D7FF 33.49%, #F3DBD9 63.44%, #FFE0A9 95.62%, #FFFDBD 120.29%)",
          }}
          onClick={handleGetStarted}
        >
          Get Started
          <ArrowRightIcon height={20} width={20} />
        </Button>
      </Stack>
    </Stack>
  );
};
