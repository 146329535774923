import { useEffect, useMemo, useState } from "react";

import type { User } from "@js/types/auth";

import { useGetEmployerInfoQuery } from "../../api";
import { useAirAiPopupModal } from "../../views/dashboard/air-ai-popup-modal/use-air-ai-popup-modal";
import { useAtsIntegrationPopupModal } from "../../views/dashboard/ats-integration-popup-modal/use-ats-integration-popup-modal";

type Announcement = {
  isIntroduced?: boolean;
  isLoading: boolean;
  canOpenAnnouncement: boolean;
  openAnnouncement: () => void;
};

export const useAnnouncementsModule = ({ user }: { user: User | null }) => {
  const { data: employerProfile } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );

  const airAiAnnouncement = useAirAiPopupModal() as Announcement;
  const atsAnnouncement = useAtsIntegrationPopupModal({
    employerProfile,
  }) as Announcement;

  const [index, setIndex] = useState<number>(0);

  const announcements = useMemo(() => {
    return [atsAnnouncement, airAiAnnouncement];
  }, [airAiAnnouncement, atsAnnouncement]);

  useEffect(() => {
    if (
      !employerProfile ||
      !announcements[index] ||
      announcements[index]?.isIntroduced === undefined ||
      announcements[index]?.isLoading
    ) {
      return;
    }

    if (announcements[index]?.canOpenAnnouncement) {
      announcements[index]?.openAnnouncement();
    } else {
      setIndex(index + 1);
    }
  }, [employerProfile, index, announcements]);
};
