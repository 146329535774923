import { useCallback } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";

import { openAirAiPopupModal } from ".";

export const useAirAiPopupModal = () => {
  const { showAIRInterview } = useAIRInterview();

  const { data: hasAirAiIntroduced, isLoading: isLoadingAirAiIntroduced } =
    useGetStorageValueQuery({
      key: FRONTEND_STORAGE_KEYS.is_air_ai_introduced,
    });
  const [setStorageValue] = useSetStorageValueMutation();

  const dismissAirAiModal = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_air_ai_introduced,
      value: true,
    });
  }, [setStorageValue]);

  return {
    canOpenAnnouncement:
      !hasAirAiIntroduced && !isLoadingAirAiIntroduced && showAIRInterview,
    isIntroduced: hasAirAiIntroduced,
    isLoading: isLoadingAirAiIntroduced,
    openAnnouncement: () => openAirAiPopupModal({ onClose: dismissAirAiModal }),
  };
};
