import { useMemo } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { useManagedEmployer } from "@js/apps/employer/hooks";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";
import { isDaysFromTodayEqualOrHigher } from "@js/utils";

const DAYS_THRESHOLD = 2;

export const useShowAirAiTopBar = () => {
  const { data: employerProfile } = useManagedEmployer();
  const { showAIRInterview } = useAIRInterview();

  const {
    data: storedDate,
    isLoading,
    isError,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.air_ai_top_bar_dismissed_at },
    { skip: !showAIRInterview },
  );

  const showAirAiTopBar = useMemo(() => {
    if (!showAIRInterview || !employerProfile || isLoading || isError) {
      return false;
    }

    if (!storedDate || typeof storedDate !== "string") {
      return true;
    }
    const shouldDisplayTopBarAgain = isDaysFromTodayEqualOrHigher(
      storedDate,
      DAYS_THRESHOLD,
    );

    return shouldDisplayTopBarAgain;
  }, [isLoading, storedDate, employerProfile, isError, showAIRInterview]);

  return { showAirAiTopBar };
};
