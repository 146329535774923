import React, { useMemo } from "react";

import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  DollarIcon,
  FlagIcon,
  InfoIcon,
  LocationPinIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { getWorkTimeLabel } from "@js/apps/jobs/utils";
import { Date } from "@js/components/date";
import type {
  JobLocation,
  JobTimezone,
  MainJobListingJob,
} from "@js/types/jobs";
import {
  dateDifference,
  formatBudget,
  getEnumLabel,
  pluralize,
} from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

import {
  DEFAULT_LOCATION_LABEL_CHARACTER_LIMIT,
  DEFAULT_MAX_NUMBER_OF_LOCATIONS,
  JOB_DETAILS_VIEW_LOCATION_LABEL_CHARACTER_LIMIT,
  JOB_DETAILS_VIEW_MAX_NUMBER_OF_LOCATIONS,
} from "./constants";
import { TimezoneOverlapTooltip } from "./timezone-overlap";
import {
  getFullLocationLabel,
  getFullTimezoneLabel,
  getLocationLabel,
  getTimezoneLabel,
} from "./utils";

export type JobBasicDetailsVariant =
  | "listing"
  | "details"
  | "listing-expanded"
  | "jobs-for-you-card";

export type DetailLabelProps = {
  icon?: JSX.Element;
  variant: JobBasicDetailsVariant;
};

export type DetailProps = {
  job: MainJobListingJob;
  variant: JobBasicDetailsVariant;
};

export type BudgetDetailProps = {
  job: MainJobListingJob;
  variant: JobBasicDetailsVariant;
  shortened?: boolean;
};

export const BudgetDetail = ({
  job,
  variant,
  shortened,
}: BudgetDetailProps) => {
  return (
    <DetailLabel
      icon={<DollarIcon style={{ fontSize: "20px" }} />}
      variant={variant}
    >
      {getJobCardBudget(job, shortened)}
    </DetailLabel>
  );
};

type LocationDetailProps = {
  locations: JobLocation[] | null;
  variant: JobBasicDetailsVariant;
};

export const LocationDetail = ({ locations, variant }: LocationDetailProps) => {
  const isInJobDetailsView = variant === "details";
  const isInJobsForYouCardView = variant === "jobs-for-you-card";

  const maxNumberOfLocations = isInJobDetailsView
    ? JOB_DETAILS_VIEW_MAX_NUMBER_OF_LOCATIONS
    : DEFAULT_MAX_NUMBER_OF_LOCATIONS;

  const characterLimit =
    isInJobDetailsView || isInJobsForYouCardView
      ? JOB_DETAILS_VIEW_LOCATION_LABEL_CHARACTER_LIMIT
      : DEFAULT_LOCATION_LABEL_CHARACTER_LIMIT;

  const locationLabel = useMemo(
    () => getLocationLabel(locations, maxNumberOfLocations, characterLimit),
    [locations, maxNumberOfLocations, characterLimit],
  );

  const tooltipFullLabel = useMemo(
    () => getFullLocationLabel(locations),
    [locations],
  );

  return (
    <DetailLabel
      icon={<LocationPinIcon style={{ fontSize: "16px" }} />}
      variant={variant}
    >
      {locationLabel}
      {tooltipFullLabel && <DetailTooltipHelper title={tooltipFullLabel} />}
    </DetailLabel>
  );
};

type TimezoneDetailProps = {
  timezones: JobTimezone[] | null;
  timezoneOverlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null;
  variant: JobBasicDetailsVariant;
  shortenedLabel?: boolean;
};

export const TimezoneDetail = ({
  timezones,
  timezoneOverlap,
  variant,
  shortenedLabel,
}: TimezoneDetailProps) => {
  const isInJobDetailsView = variant === "details";

  const maxNumberOfTimezones = isInJobDetailsView
    ? JOB_DETAILS_VIEW_MAX_NUMBER_OF_LOCATIONS
    : DEFAULT_MAX_NUMBER_OF_LOCATIONS;

  const timezoneLabel = getTimezoneLabel(timezones, maxNumberOfTimezones);
  const tooltipTimezoneFullLabel = getFullTimezoneLabel(timezones);

  if (!timezones && variant === "jobs-for-you-card") return null;

  const shouldMoveTimezoneOverlapInfoToTooltip = Boolean(
    timezones && timezones.length > maxNumberOfTimezones,
  );

  if (shortenedLabel) {
    return (
      <DetailLabel
        icon={<ClockIcon style={{ fontSize: "16px" }} />}
        variant={variant}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {timezoneLabel}
          {tooltipTimezoneFullLabel && (
            <DetailTooltipHelper
              title={getTimezoneDetailTooltipText(
                tooltipTimezoneFullLabel,
                shouldMoveTimezoneOverlapInfoToTooltip,
                timezoneOverlap,
              )}
            />
          )}
        </Box>
      </DetailLabel>
    );
  }

  return (
    <DetailLabel
      icon={<ClockIcon style={{ fontSize: "16px" }} />}
      variant={variant}
    >
      <Box>
        {timezoneLabel}
        {tooltipTimezoneFullLabel && (
          <DetailTooltipHelper
            title={getTimezoneDetailTooltipText(
              tooltipTimezoneFullLabel,
              shouldMoveTimezoneOverlapInfoToTooltip,
              timezoneOverlap,
            )}
          />
        )}
        {!shouldMoveTimezoneOverlapInfoToTooltip && (
          <TimezoneOverlapTooltip overlap={timezoneOverlap}>
            {timezoneOverlap && ` | ${getOverlapLabel(timezoneOverlap)}`}
          </TimezoneOverlapTooltip>
        )}
      </Box>
    </DetailLabel>
  );
};

type WorkTimeDetailProps = {
  expectedHoursPerWeek: number | null;
  variant: JobBasicDetailsVariant;
  shortened?: boolean;
  shortenedLabel?: boolean;
};

export const WorkTimeDetail = ({
  expectedHoursPerWeek,
  variant,
  shortened,
  shortenedLabel,
}: WorkTimeDetailProps) => {
  if (!expectedHoursPerWeek) {
    return null;
  }

  const suffix = shortened ? "hrs/wk" : "hours per week";
  const hours = expectedHoursPerWeek;

  return (
    <DetailLabel
      icon={<CalendarIcon style={{ fontSize: "16px" }} />}
      variant={variant}
    >
      {shortenedLabel
        ? `${getWorkTimeLabel(hours)} | ${expectedHoursPerWeek} ${suffix}`
        : `${expectedHoursPerWeek} ${suffix}`}
    </DetailLabel>
  );
};

export const ContractTypeDetail = ({ job, variant }: DetailProps) => {
  if (
    job.job_type === ENUMS.JobType.GRANT &&
    job?.start_date &&
    job?.deadline
  ) {
    const monthDifference = Number(
      dateDifference(job.deadline, job.start_date, "months").toFixed(0),
    );

    return (
      <DetailLabel
        icon={<BriefcaseIcon style={{ fontSize: "16px" }} />}
        variant={variant}
      >
        {monthDifference > 0
          ? `${monthDifference} month${pluralize(monthDifference)}`
          : "1 month"}
      </DetailLabel>
    );
  }

  if (
    !job?.contract_type ||
    job?.contract_type === ENUMS.JobContractType.UNKNOWN
  ) {
    return null;
  }

  return (
    <DetailLabel
      icon={<BriefcaseIcon style={{ fontSize: "16px" }} />}
      variant={variant}
    >
      {getEnumLabel(ENUMS.JobContractTypeLabels, job?.contract_type)}
    </DetailLabel>
  );
};

export const DeadlineDetail = ({ variant, job }: DetailProps) => {
  const startDate = job?.start_date || "";
  const endDate = job?.deadline || "";

  let _label;

  if (!startDate && !endDate) {
    return null;
  } else if (!!startDate && !endDate) {
    _label = (
      <>
        Starts <Date date={startDate} format={DateFormats["Jan 1, 1970"]} />
      </>
    );
  } else if (!startDate && !!endDate) {
    _label = (
      <>
        Ends <Date date={endDate} format={DateFormats["Jan 1, 1970"]} />
      </>
    );
  } else {
    _label = (
      <>
        <Date date={startDate} format={DateFormats["Jan 1, 1970"]} /> –{" "}
        <Date date={endDate} format={DateFormats["Jan 1, 1970"]} />
      </>
    );
  }

  return (
    <DetailLabel
      icon={<FlagIcon style={{ fontSize: "16px" }} />}
      variant={variant}
    >
      {_label}
    </DetailLabel>
  );
};

const getOverlapLabel = (
  overlap: EnumType<typeof ENUMS.JobTimezoneOverlapType>,
) => {
  const label = getEnumLabel(ENUMS.JobTimezoneOverlapTypeLabels, overlap);

  if (label === ENUMS.JobTimezoneOverlapTypeLabels.full) {
    return "Full day overlap";
  }

  if (label === ENUMS.JobTimezoneOverlapTypeLabels.partial) {
    return "Partial overlap";
  }

  return label;
};

const addTimezoneOverlapInfo = (
  timezoneLabel: string,
  overlap: EnumType<typeof ENUMS.JobTimezoneOverlapType>,
) => {
  const overlapInfo =
    overlap === ENUMS.JobTimezoneOverlapType.PARTIAL
      ? "Talent needs to be able to overlap at least 3-4 of their working hours with the time zone shown."
      : "Talent needs to be able to overlap a full work day with the time zone shown.";

  return timezoneLabel + " | " + getOverlapLabel(overlap) + ": " + overlapInfo;
};

const getTimezoneDetailTooltipText = (
  tooltipTimezoneFullLabel: string,
  shouldMoveTimezoneOverlapInfoToTooltip: boolean,
  timezoneOverlap: EnumType<typeof ENUMS.JobTimezoneOverlapType> | null,
) => {
  if (!timezoneOverlap || !shouldMoveTimezoneOverlapInfoToTooltip) {
    return tooltipTimezoneFullLabel;
  }

  return addTimezoneOverlapInfo(tooltipTimezoneFullLabel, timezoneOverlap);
};

const getJobCardBudget = (job: MainJobListingJob, shortened?: boolean) => {
  const formattedBudget = formatBudget(
    Math.round(Number(job.budget_minimum_usd)),
    Math.round(Number(job.budget_maximum_usd)),
    {
      paymentType: job.payment_type,
      removeDecimal: true,
      hourlyRateSuffix: shortened ? "/hr" : " per hour",
    },
  );

  return (formattedBudget as string).split("$").join("");
};

const DetailLabel: React.FC<React.PropsWithChildren<DetailLabelProps>> = ({
  children,
  icon,
  variant,
}) => {
  const iconSpacing =
    variant === "listing-expanded"
      ? 1.5
      : variant === "jobs-for-you-card"
        ? 0.75
        : 1;

  if (
    variant === "listing" ||
    variant === "listing-expanded" ||
    variant === "jobs-for-you-card"
  ) {
    return (
      <Box display="flex" alignItems="flex-start">
        {icon && (
          <Box
            display="flex"
            paddingTop="3px"
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Box>
        )}
        <Typography
          component="div"
          variant="paragraph"
          whiteSpace="normal"
          size="small"
          className="capitalize-first-letter"
          sx={{
            ml: iconSpacing,
            overflowWrap: "break-word",
            zIndex: 1,
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  }

  if (variant === "details") {
    return (
      <Box display="flex" alignItems="center">
        {icon && (
          <Box display="flex" justifyContent="center" alignItems="center">
            {icon}
          </Box>
        )}
        <Typography
          component="div"
          variant="paragraph"
          whiteSpace="normal"
          size="medium"
          className="capitalize-first-letter"
          sx={{ ml: 1, wordBreak: "none" }}
        >
          {children}
        </Typography>
      </Box>
    );
  }

  return null;
};

const DetailTooltipHelper = ({ title }: { title: string }) => {
  return (
    <Tooltip maxWidth={320} placement="top" title={title}>
      <Box component="span" sx={{ display: "inline-block", px: "5px" }}>
        <InfoIcon sx={{ fontSize: "14px", mb: "-2px" }} aria-label="Info" />
      </Box>
    </Tooltip>
  );
};
