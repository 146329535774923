import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { mapEmailErrorCode } from "@js/apps/auth/utils";
import { createOTPModalInstance } from "@js/apps/common/forms/fields/otp/otp-auth-modal";
import { useAccountType } from "@js/apps/common/hooks";
import { PasswordConfirmationField } from "@js/apps/common/password-confirmation-modal";
import { Section, SectionSubtitle } from "@js/apps/settings/forms/helpers";
import { Snackbar } from "@js/components/snackbar";
import { Form } from "@js/forms/components/form";
import {
  GooglePlacesSingleField,
  mapAddressComponentsToAddress,
  SelectField,
  TextField,
} from "@js/forms/fields";
import { linkFormatValidator } from "@js/forms/validators";
import type { GoogleLocation } from "@js/types/common";
import { countrySelectOptions, displayTimezoneName } from "@js/utils";

import { LanguagesField } from "./languages-field";

export const ACCOUNT_INFO_FORM_ID = "account-info-form";

export const {
  openAccountInfoOTPModal,
  closeAccountInfoOTPModal,
  AccountInfoOTPModalInstance,
} = createOTPModalInstance("accountInfoOTPModal");

type AccountInfoFormContentProps = {
  submit: () => void;
  submitting: boolean;
  timezones: typeof SETTINGS.TIMEZONE_ABBREVIATIONS;
  setIsSaveDisabled: (arg: boolean) => void;
  error?: string;
  form: string;
};

const AccountInfoFormContent = ({
  submit,
  submitting,
  timezones,
  setIsSaveDisabled,
  error,
  form,
}: AccountInfoFormContentProps) => {
  const { isFreelancer, isEmployer } = useAccountType();
  return (
    <Form onSubmit={submitting ? null : submit} error={error} id={form}>
      <Section title="Account Info">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SectionSubtitle>General</SectionSubtitle>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="first_name"
              name="first_name"
              component={TextField}
              label="First Name"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="last_name"
              name="last_name"
              component={TextField}
              label="Last Name"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="email"
              name="email"
              component={TextField}
              mapErrorCode={mapEmailErrorCode}
              label="Email Address"
            />
          </Grid>
        </Grid>
      </Section>

      {isEmployer && (
        <Section title="Applications">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="p">
                Add a scheduling link so you can quickly interview talent. This
                link will be sent to your interviewee when you want to speak
                with them.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                variant="outlined"
                id="calendar_link"
                name="calendar_link"
                component={TextField}
                validate={[linkFormatValidator]}
                label="Scheduling Link"
              />
            </Grid>
          </Grid>
        </Section>
      )}

      {isFreelancer && (
        <Section title="Applications">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="p">
                Add an optional link to your job applications, directing clients
                to your preferred calendar scheduling tool (i.e. mixmax,
                calendly, etc). This makes it easy for clients to schedule
                interviews with you.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                variant="outlined"
                id="calendar_link"
                name="calendar_link"
                component={TextField}
                label="Calendly link"
              />
            </Grid>
          </Grid>
        </Section>
      )}

      {isFreelancer && (
        <Section title="Location">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="p">
                This will be used everywhere except for billing/invoicing. It'll
                help us provide better search/job matching experience.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                id="full_location"
                name="full_location"
                label={undefined}
                aria-label="Enter your city"
                onGetLocationEnd={() => {
                  setIsSaveDisabled(false);
                }}
                onGetLocationStart={() => {
                  setIsSaveDisabled(true);
                }}
                component={GooglePlacesSingleField}
              />
            </Grid>
          </Grid>
        </Section>
      )}

      <Section title="Region &amp; language">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SectionSubtitle>Billing Address</SectionSubtitle>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              id="address.country"
              name="address.country"
              component={SelectField}
              options={countrySelectOptions}
              label="Country"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="address.state"
              name="address.state"
              component={TextField}
              label="State / Province"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="address.address1"
              name="address.address1"
              component={TextField}
              label="Address Line 1"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="address.address2"
              name="address.address2"
              component={TextField}
              label="Address Line 2"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="address.city"
              name="address.city"
              component={TextField}
              label="City"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="address.zipcode"
              name="address.zipcode"
              component={TextField}
              label="ZIP / Postal Code"
            />
          </Grid>
          {!isFreelancer && (
            <Grid item sm={6} xs={12}>
              <Field
                id="timezone"
                name="timezone"
                component={SelectField}
                options={timezones.map((timezone) => ({
                  label: (
                    <>
                      <span className="color-gray-2">
                        {timezone.utc_offset}
                      </span>{" "}
                      {displayTimezoneName(timezone.name)}
                    </>
                  ),
                  labelText: `${timezone.utc_offset} - ${displayTimezoneName(
                    timezone.name,
                  )}`,
                  value: timezone.name,
                }))}
                label="Timezone"
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={4}>
          <FieldArray name="user_languages" component={LanguagesField} />
        </Grid>
      </Section>

      <Section title="Referral Link">
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <Field
              variant="outlined"
              id="referral_link"
              name="referral_link"
              component={TextField}
              label="Referral URL"
              disabled
            />
          </Grid>
        </Grid>
      </Section>
      <AccountInfoOTPModalInstance />
      <PasswordConfirmationField />
    </Form>
  );
};

export type AccountInfoFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  address: AccountInfoFormAddress;
  timezone: string;
  referral_link: string;
  user_languages: AccountInfoFormUserLanguage[];
  calendar_link: string;
  full_location: string | GoogleLocation;
  code?: string;
};

type AccountInfoFormAddress = {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  country_name: string;
  text: string;
};

type AccountInfoFormUserLanguage = {
  language_id: number;
  skill_level: string;
};

export const AccountInfoForm = reduxForm<AccountInfoFormValues, any>({
  form: ACCOUNT_INFO_FORM_ID,
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (
      !values.address &&
      values.full_location &&
      typeof values.full_location === "object" &&
      values.full_location?.address_components
    ) {
      const { city, state, country } = mapAddressComponentsToAddress(
        values.full_location.address_components,
      );

      dispatch(props.change("address.city", city));
      dispatch(props.change("address.state", state));
      dispatch(props.change("address.country", country));
    }
  },
  onSubmitFail: (errors) => {
    if (!errors) {
      return;
    }

    if (errors._error) {
      Snackbar.error(errors._error);
    }
  },
})(AccountInfoFormContent);
