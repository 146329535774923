import { useLocation } from "react-router-dom";

import { UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { useAIRInterview } from "../../hooks/use-air-interview";

type JobManagementNavProps = {
  job: Job;
  totalBidsCount: number;
  totalInterviewingBidsCount: number;
};

export const JobManagementNav = ({
  job,
  totalBidsCount,
  totalInterviewingBidsCount,
}: JobManagementNavProps) => {
  const { pathname } = useLocation();
  const { showAIRInterview } = useAIRInterview();
  const navLinks = getNavLinks({
    job,
    bidsCount: totalBidsCount,
    interviewingBidsCount: totalInterviewingBidsCount,
    airInterviewEnabled: showAIRInterview,
  });

  return (
    <UnderlinedTabs
      data-testid="job-management-nav"
      items={navLinks}
      pathname={pathname}
      labelUnActiveColor="grey"
      spacing={3}
      itemProps={{
        sx: { p: "0" },
        variant: "title",
        size: "small",
        fontWeight: 400,
      }}
      RouterLink={RouterLink}
    />
  );
};

type GetNavLinksProps = {
  job: Job;
  bidsCount: number;
  interviewingBidsCount: number;
  airInterviewEnabled: boolean;
};

export const getNavLinks = ({
  job,
  bidsCount,
  interviewingBidsCount,
  airInterviewEnabled,
}: GetNavLinksProps) => {
  const navLinks = [
    {
      label: "Invite Talent",
      path: `/jobs/${job.id}/invite_talent/`,
    },
    {
      label: `${bidsCount} Application${pluralize(bidsCount, {
        zeroPlural: true,
      })}`,
      path: `/jobs/${job.id}/proposals/`,
    },
  ];

  return !airInterviewEnabled
    ? navLinks
    : [
        ...navLinks,
        {
          label: `${interviewingBidsCount} Interviewing`,
          path: `/jobs/${job.id}/interviews/?ordering=-ai_score`,
        },
      ];
};
