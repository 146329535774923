import { Field } from "redux-form";

import type { WrappedFieldProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ErrorSVGIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CheckboxField } from "@js/forms/fields";
import type { TypedWrappedFieldProps } from "@js/forms/utils";
import type { EmployerBidListBid } from "@js/types/jobs";

import { calendarLinkValidator } from "./validator";

type SetupCalendarFieldsProps = {
  calendar_link: WrappedFieldProps;
  save_as_default: WrappedFieldProps;
  freelancer: EmployerBidListBid["freelancer"];
  onConfirm: () => void;
};

export const SetupCalendarFields = ({
  freelancer,
  onConfirm,
}: SetupCalendarFieldsProps) => {
  const name = freelancer?.user?.first_name;

  return (
    <>
      <Typography component="h1" variant="title" fontWeight={400} size="small">
        Send scheduling link
      </Typography>
      <Box mt={1}>
        Advance {name} to the next round by sending a scheduling link to allow
        them to book a time on your calendar.
      </Box>

      <Box mt={3} mb={4}>
        <Field
          name="calendar_link"
          component={CalendarLinkTextField}
          validate={[calendarLinkValidator]}
        />
        <Field
          id="save_as_default"
          name="save_as_default"
          component={CheckboxField}
          size="small"
          label={
            <Typography component="p" variant="paragraph" size="small">
              Save as my default scheduling link
            </Typography>
          }
        />
      </Box>
      <Button variant="primary" shape="squared" onClick={onConfirm}>
        Confirm details
      </Button>
    </>
  );
};

const CalendarLinkTextField = ({
  input,
  meta,
}: TypedWrappedFieldProps<string>) => {
  return (
    <Stack>
      <TextField
        id="calendar_link"
        className="calendar_link_input"
        label="Your scheduling link"
        placeholder="Enter your link"
        value={input.value}
        error={meta?.touched && !!meta?.error}
        onChange={(evt) => input.onChange(evt.target.value)}
      />
      {meta?.touched && meta?.error && (
        <Typography
          component="span"
          variant="paragraph"
          color="negative"
          size="small"
          marginTop={1}
          display="flex"
          alignItems="center"
          gap={0.5}
        >
          <ErrorSVGIcon fontSize="small" />
          {meta?.error}
        </Typography>
      )}
    </Stack>
  );
};
