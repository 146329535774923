import { useCallback } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import type { Employer } from "@js/types/employer";

import { openATSIntegrationPopupModal } from ".";

export const useAtsIntegrationPopupModal = ({
  employerProfile,
}: {
  employerProfile: Employer | undefined;
}) => {
  const {
    data: hasAtsIntegrationIntroduced,
    isLoading: isLoadingAtsIntegrationIntroduced,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.is_ats_introduced },
    { skip: !employerProfile?.can_create_linked_account },
  );
  const [setStorageValue] = useSetStorageValueMutation();

  const dismissAtsIntegrationModal = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_ats_introduced,
      value: true,
    });
  }, [setStorageValue]);

  return {
    canOpenAnnouncement:
      employerProfile?.can_create_linked_account &&
      !hasAtsIntegrationIntroduced &&
      !isLoadingAtsIntegrationIntroduced,
    isIntroduced: hasAtsIntegrationIntroduced,
    isLoading: isLoadingAtsIntegrationIntroduced,
    openAnnouncement: () =>
      openATSIntegrationPopupModal({ onClose: dismissAtsIntegrationModal }),
  };
};
