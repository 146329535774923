import { useMemo } from "react";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useStatsQuery } from "@js/apps/common/api";
import { useUser } from "@js/apps/common/hooks";
import { ATSIntegrationDashboardModule } from "@js/apps/employer/components/ats-integration-dashboard-module";
import { EliteTalentModule } from "@js/apps/employer/components/elite-talent-module";
import { LetPostJob } from "@js/apps/employer/components/let-post-job";
import { OpenJobsModule } from "@js/apps/employer/components/open-jobs-module";
import { PrompToPostJob } from "@js/apps/employer/components/prompt-to-post-job";
import { useAnnouncementsModule } from "@js/apps/employer/hooks/announcements-module";
import { useLetPostJobModule } from "@js/apps/employer/hooks/let-post-job-module";
import { round } from "@js/utils";

import { EmployerDashboardLayout } from "../layout";

import style from "./style.module.scss";

export const EmployerDashboardWelcomePage = () => {
  const user = useUser();
  const isMobile = useMediaQuery("sm");

  useAnnouncementsModule({ user });

  return (
    <EmployerDashboardLayout
      contentClassName={style.employerDashboardContainer}
      pageTitle="Welcome"
      bgcolor="var(--soft-violet)"
    >
      <Typography
        component="h1"
        variant="title"
        size={isMobile ? "medium" : "small"}
        textTransform="capitalize"
        fontWeight={400}
        data-testid="dashboard-user-name"
        mb={3}
      >
        Hi, {user?.first_name}!
      </Typography>
      <div className={style.employerDashboardWelcome}>
        <DashboardContent />
      </div>
    </EmployerDashboardLayout>
  );
};

export const DashboardContent = () => {
  const { showFirstJobPromptModule, isLoading: isLoadingJobs } =
    useLetPostJobModule();
  const { data: stats, isLoading: isLoadingStats } = useStatsQuery();
  const userCountToDisplay = useMemo(() => {
    return round(stats?.users_count, 100).toLocaleString("en-US");
  }, [stats?.users_count]);

  const isLoading = isLoadingStats || isLoadingJobs;

  if (isLoading) {
    return <Loader />;
  }

  if (showFirstJobPromptModule) {
    return (
      <EmployerDashboardNoJobsContent userCountToDisplay={userCountToDisplay} />
    );
  }

  return (
    <EmployerDashboardWithJobsContent userCountToDisplay={userCountToDisplay} />
  );
};

type EmployerDashboardNoJobsContentProps = {
  userCountToDisplay: string;
};

const EmployerDashboardNoJobsContent = ({
  userCountToDisplay,
}: EmployerDashboardNoJobsContentProps) => {
  return (
    <>
      <LetPostJob talentCountsToDisplay={userCountToDisplay} />
      <div className={style.employerDashboardWelcomeColumnLeft}>
        <ATSIntegrationDashboardModule />
      </div>
      <div className={style.employerDashboardWelcomeColumnRight}>
        <EliteTalentModule />
      </div>
    </>
  );
};

type EmployerDashboardWithJobsContentProps =
  EmployerDashboardNoJobsContentProps;

const EmployerDashboardWithJobsContent = ({
  userCountToDisplay,
}: EmployerDashboardWithJobsContentProps) => {
  return (
    <>
      <div className={style.employerDashboardWelcomeColumnLeft}>
        <PrompToPostJob talentCountsToDisplay={userCountToDisplay} />
        <ATSIntegrationDashboardModule />
      </div>
      <div className={style.employerDashboardWelcomeColumnRight}>
        <OpenJobsModule />
        <EliteTalentModule />
      </div>
    </>
  );
};
