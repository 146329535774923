import { useCallback } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useShowAirAiTopBar } from "@js/apps/dashboard/hooks/use-show-air-ai-top-bar";
import { openCreateJobModal } from "@js/apps/employer/components/create-job-modal";
import { useAppSelector, useNavigate } from "@js/hooks";

export const useAirAiTopBar = () => {
  const profile = useAppSelector((state) => state.employer.employerProfile);
  const navigate = useNavigate();

  const [setStorageValue] = useSetStorageValueMutation();
  const { showAirAiTopBar } = useShowAirAiTopBar();

  const dismissBar = () => {
    const currentDate = new Date().toUTCString();
    return setStorageValue({
      key: FRONTEND_STORAGE_KEYS.air_ai_top_bar_dismissed_at,
      value: currentDate,
    });
  };

  const onGetStarted = useCallback(() => {
    if (!profile) return;

    navigate("/employer/dashboard/my_jobs/");

    if (profile?.open_jobs_count === 0) {
      openCreateJobModal();
    }
  }, [profile, navigate]);

  return {
    dismissBar,
    showTopBar: !!profile && showAirAiTopBar,
    onGetStarted,
  };
};
