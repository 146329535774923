import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import { useAirAiTopBar } from "./hooks/use-air-ai-top-bar";

export const AirAiTopBar = () => {
  const { showTopBar, dismissBar, onGetStarted } = useAirAiTopBar();

  if (!showTopBar) {
    return null;
  }

  return (
    <Box className="air-ai-bar">
      <Box
        className="air-ai-bar__content"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box className="air-ai-bar__icon_content">
          <img
            src={`${SETTINGS.STATIC_URL}jobs/ai-icon.svg`}
            alt="ai job"
            height={31}
          />
          <Typography
            component="span"
            fontWeight={500}
            size="small"
            className="air-ai-bar__pill"
          >
            Speed up your hiring 2x with AI Interviews
          </Typography>
        </Box>
        <Typography component="span" size="small" className="air-ai-bar__copy">
          Enable our AI Interviewer on any open role to make fast, informed
          hiring decisions.{" "}
          <Button
            variant="no-padding"
            size="x-small"
            sx={{
              verticalAlign: "baseline",
            }}
            onClick={onGetStarted}
            endIcon={<ArrowRightIcon />}
          >
            Get started
          </Button>
        </Typography>
      </Box>
      <IconButton
        size="x-small"
        variant="primary"
        aria-label="Dismiss air ai bar"
        onClick={dismissBar}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
